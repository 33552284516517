jQuery(function($) {
    $('#easypiechart-teal').easyPieChart({
        scaleColor: false,
        barColor: '#1ebfae'
    });

    $('#easypiechart-orange').easyPieChart({
        scaleColor: false,
        barColor: '#ffb53e'
    });

    $('#easypiechart-red').easyPieChart({
        scaleColor: false,
        barColor: '#f9243f'
    });

    $('#easypiechart-blue').easyPieChart({
        scaleColor: false,
        barColor: '#30a5ff'
    });
});
 