/**
 * MomentJS Config
 */
moment.locale('pt_BR');

/**
 *
 * @type {Intl.NumberFormat}
 */
var numberFormatter = new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2});

/**
 * Functions
 * @returns {*|jQuery|HTMLElement}
 */
function createRow() {
    var row = $('<tr/>');

    for (var i in arguments) {
        row.append($('<td/>').html(arguments[i]));
    }

    return row;
}

function create_table_row(row) {
    var tr = $('<tr>');

    for (var i in row) {
        var cell = row[i];
        var td = $('<td>');

        if (cell !== null && typeof cell === "object" && cell.hasOwnProperty('content')) {
            td.append(cell.content);

            for (var k in cell) {
                if (k !== "content") {
                    td.attr(k, cell[k]);
                }
            }
        }
        else if (Array.isArray(cell)) {
            $.each(cell, function (k, value) {
                td.append(value);
            });
        }
        else {
            td.append(cell);
        }

        tr.append(td);
    }

    return tr;
}

function table_append_rows(table, rows) {
    var count = 0;

    for (var i in rows) {
        table.append(create_table_row(rows[i]));

        count++;
    }

    if (!table.is('table')) {
        table = table.closest('table') || table;
    }

    $(table).trigger('table-changed', table);

    return count;
}

function table_replace_row(table, oldRow, newRow) {
    if (Array.isArray(newRow) || !$(newRow).is('tr')) {
        newRow = create_table_row(newRow);
    }

    if (!oldRow) {
        table.append(newRow);
    }

    newRow.insertAfter(oldRow);

    if (oldRow) {
        oldRow.remove();
    }

    if (!table.is('table')) {
        table = table.closest('table') || table;
    }

    $(table).trigger('table-changed', table);
}


/**
 * REST Buttons
 */
jQuery(function ($) {
    $('.rest').restfulizer({
        parse: true
    });

    window.confirmSubmitRestButton = function (e) {
        var form = $(this);
        var a = form.closest('a');

        if (form.data('force-submit')) {
            return true;
        }

        e.preventDefault();

        swal({
            title: 'Atenção!',
            html: $(a).data('confirm') || 'Você tem certeza disso?',
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#dd4331',
            confirmButtonText: 'Sim',
            cancelButtonText: "Cancelar",
            focusConfirm: false,
            focusCancel: true
        }).then(function (result) {
            if (result.hasOwnProperty('value') && result.value === true) {
                swal({
                    title: 'Aguarde!',
                    text: 'Estamos processando sua operação!',
                    type: 'info',
                    showConfirmButton: false,
                    timer: 500
                }).then(function () {
                    form.data('force-submit', true);
                    form.submit();
                });
            } else {
                form.removeData('force-submit');
            }
        });
    };

    /*
        Rest buttons confirm message
     */
    $('a[data-confirm] form').on('submit', window.confirmSubmitRestButton);
});

/**
 * Tables with clickables rows
 */
jQuery(function ($) {
    $('body').on('click', 'table.table-clickable-rows tbody tr td[data-href]', function () {
        var $this = this;
        var href = $(this).data('href');
        var target = $(this).data('target');

        if (/^javascript:/.test(href)) {
            return (function () {
                eval(href.replace(/^javascript:\s*/, ''));
            }).call($this);
        }

        return window.open(href, target || '_self');
    });
});

/**
 * Table Colspan Rows
 */
jQuery(function ($) {
    $('td.colspan').each(function () {
        var cols = $(this).closest('table')
            .find('thead tr:last').find('th,td').length;

        if (cols > 0) {
            $(this).attr('colspan', cols);
        }
    });
});

jQuery(function ($) {
    $('.mask-money').mask('#.##0,00', {reverse: true});
    $('.mask-digits').mask('###0', {reverse: true});
    $('.mask-digits-s').mask('#.##0', {reverse: true});

    $('.mask-cpf').mask('000.000.000-00', {reverse: true});
    $('.mask-cep').mask('00000-000');
    $('.mask-numeroano').mask('#000/0000', {reverse: true});
    $('.mask-numeroano-s').mask('#.##0/0000', {reverse: true});
    $('.mask-cnpj').mask('00.000.000/0000-00', {reverse: true});
    $('.mask-date').mask('00/00/0000');
    $('.mask-datetime').mask('00/00/0000 00:00:00');
    $('.mask-datetime-minutes').mask('00/00/0000 00:00');

    $('.mask-telefone').mask('(00) 0000-00009')
        .on('focusout', function () {
            var element = $(this);
            var phone = element.val().replace(/\D/g, '');

            element.unmask();

            if (phone.length > 10) {
                element.mask("(00) 00000-0009");
            } else {
                element.mask("(00) 0000-00009");
            }
        });
});

$.fn.select2.defaults.set("theme", "bootstrap");
$.fn.select2.defaults.set("language", "pt-BR");
$.fn.select2.defaults.set("width", "100%");

jQuery(function ($) {
    $('select.select2:not(.select2-ajax)').each(function () {
        var options = {
            templateSelection: function (item) {
                return item.hasOwnProperty('selection_text') ? item.selection_text : item.text;
            }
        };

        $(this).select2(options);
    });

    $('select.select2.select2-ajax').each(function () {
        var $this = $(this);
        var $data = $(this).data();

        var options = {
            templateSelection: function (item) {
                $this.data('selectedOption', item);

                return item.hasOwnProperty('selection_text') ? item.selection_text : item.text;
            }
        };

        $this.on('change', function () {
            if (!$this.val()) {
                $this.data('option-selected', null);
            }
        });

        var ajaxQuery = {};

        for (var key in $data) {
            var index = key + '';
            if (index.indexOf('ajaxQuery') === 0) {
                var name = index.replace(/ajaxQuery/, '');
                name = name.charAt(0).toLowerCase() + name.slice(1);

                ajaxQuery[name] = $($data[index]);
            }
        }

        $this.select2($.extend({}, options, {
            ajax: {
                url: $this.data('ajax-url'),
                method: 'POST',
                dataType: 'json',
                delay: 500,
                cache: false,
                data: function (params) {
                    var query = {
                        _token: window.Laravel.csrfToken,
                        search: params.term,
                        page: params.page || 1,
                        selected: $this.val()
                    };

                    for (var i in ajaxQuery) {
                        query[i] = ajaxQuery[i].val();
                    }

                    return query;
                }
            }
        }))
    });
});

jQuery(function ($) {
    var CHECK_COOKIE_INTERVAL = 7300;
    var FORCE_CHECK_COOKIE_INTERVAL = 20 * 1000;

    window.Laravel = window.Laravel || {csrfToken: null, baseUrl: null};

    setInterval(function () {
        var csrf = Cookies.get('XSRF-TOKEN');

        if (csrf && csrf !== window.Laravel.csrfToken) {
            window.Laravel.csrfToken = csrf;

            $('input[name="_token"]').val(csrf);
        }
    }, CHECK_COOKIE_INTERVAL);

    setInterval(function () {
        if (window.Laravel.baseUrl) {
            $.ajax(window.Laravel.baseUrl, {
                async: true,
                complete: function (response) {
                    var status = parseInt(response.status);

                    if (status === 401) { //unauthorized
                        /*
                           O usuário "teoricamente" não logado será redirecionado para tela de logout (forçar o logout)
                           e em seguida para o login.
                         */
                        window.location.assign(window.Laravel.logout_url);
                    }
                    else if(status === 403) { //forbidden
                        /*
                          Redireciona para a página de login, assim, o sistema verifica se ele está logado e em seguida
                          o redirecona para a página do cliente/admin correto.
                         */
                        window.location.assign(window.Laravel.login_url);
                    }
                }
            });
        }
    }, FORCE_CHECK_COOKIE_INTERVAL);
});

window.datetimepicker_format_ptbr = 'DD/MM/YYYY';

jQuery(function ($) {
    $('.datepicker').each(function () {
        var format = $(this).data('date-format') || datetimepicker_format_ptbr;

        $(this).datetimepicker({
            format: format
        });
    });

    $('.datetimepicker').each(function () {
        var dateTimeFormat = $(this).data('datetime-format');

        if (!dateTimeFormat) {
            var dateFormat = $(this).data('date-format') || datetimepicker_format_ptbr;
            var timeFormat = $(this).data('time-format') || 'HH:mm:ss';

            dateTimeFormat = dateFormat + ' ' + timeFormat;
        }

        $(this).datetimepicker({
            format: dateTimeFormat
        });
    });
});

jQuery(function ($) {
    $('[data-toggle=popover]').popover();
    $('[data-toggle=tooltip]').tooltip();
});

/**
 * Filtros avançados
 */
jQuery(function ($) {
    $(document).one('click', '.btn-toggle-filtros-avancados', function (e) {
        e.preventDefault();

        var fieldset = $(this).closest('fieldset');

        var cb = function (e) {
            $(this).toggleClass('filtros-hidden')
                .toggleClass('filtros-visible');
        };

        fieldset.on('click', '.btn-toggle-filtros-avancados', function (e) {
            e.preventDefault();

            cb.apply(fieldset);
        });

        cb.apply(fieldset);

        if (fieldset.hasClass('filtros-visible')) {
            fieldset.find('.select2').trigger('change');
        }
    });
});

/**
 * Showmore container
 */
jQuery(function ($) {
    $(document).on('click', '.btn-showmore', function () {
        var container = $(this).closest('.showmore-container');

        container.addClass('complete');
    });

    $(document).on('click', '.btn-showless', function () {
        var container = $(this).closest('.showmore-container');

        container.removeClass('complete');
    });
});

/**
 * Back url button
 */
jQuery(function ($) {
    $(document).on('click', '.btn-back-url', function (e) {
        e.preventDefault();

        var referrer = document.referrer ? new URL(document.referrer) : false;
        var current = new URL(document.location.toString());
        var previous = $(this).data('previous');
        var target = $(this).prop('target') || '_self';
        var emptyHistoryUrl = $(this).data('empty-history-url') || previous;

        if (history.length < 2) {
            window.open(emptyHistoryUrl, target);
        }

        else if (referrer && referrer.hostname === current.hostname && referrer.pathname !== current.pathname) {
            history.back();
        }
        else {
            window.open(previous, target);
        }
    });
});